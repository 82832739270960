import { Component } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
//import { AuthenticationService } from '../../authentication/authentication.service'
import { CountriesService } from '../../countries/countries.service'
import { CategoriesService } from '../../categories/categories.service'
import { ChannelsService } from '../../channels/channels.service'
import { ChannelSegmentsService } from '../../channelSegments/channel-segments.service'
import { FormatsService } from '../../formats/formats.service'
import { ModulesService } from '../../modules/modules.service'
import { SubChannelsService } from '../../subChannels/sub-channels.service'
import { PosRegionsService } from '../../posRegions/pos-regions.service'
import { FilesService } from '../../files/files.service'
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {


  _limits: any[] = [{ id: null, description: 'All' }, { id: 10, description: '10' }, { id: 50, description: '50' }, { id: 100, description: '100' }, { id: 250, description: '250' }, { id: 500, description: '500' }, { id: 1000, description: '1000' }];
  _activities: any[] = [{ id: null, description: 'All' }, { id: 'DPSM-D', description: 'Distribution' }, { id: 'DPSM-M', description: 'Merchandising' }, { id: 'DPSM-P', description: 'Pricing' }, { id: 'DPSM- S', description: 'Shelving' }];

  _countries: any[] = [];
  _categories: any[] = [];
  _channels: any[] = [];
  _channelsFiltered: any[] = [];
  _channelSegments: any[] = [];
  _channelSegmentsFiltered: any[] = [];
  _formats: any[] = [];
  _formatsFiltered: any[] = [];
  _modules: any[] = [];
  _modulesFiltered: any[] = [];
  _posRegions: any[] = [];
  _posRegionsFiltered: any[] = [];
  _subChannels: any[] = [];
  _subChannelsFiltered: any[] = [];
  _responsibles: any[] = [];
  _customers: any[] = [];
  _pos: any[] = [];
  _kpis: any[] = [];
  _tags: any[] = [];
  _displayGroups: any[] = [];
  _displayTypes: any[] = [];
  _weeks: any[] = [];

  _years: any[] = [];
  _files: any[] = [];
  _filesFinal: any[] = [];
  _filesFiltered: any[] = [];
  selectedCustomers!: any[];
  selectedDisplayGroups!: any[];
  selectedDisplayTypes!: any[];
  selectedPos!: any[];
  selectedResponsibles!: any[];
 
 // tokenBearer: string = '';

  countrySelected: any = null;
  categorySelected: any = null;
  channelSelected: any = null;
  channelSegmentSelected: any = null;
  formatSelected: any = null;
  moduleSelected: any = null;
  posRegionSelected: any = null;
  subChannelSelected: any = null;
  responsibleSelected: any = null;
  kpiSelected: any = null;
  limitSelected: any = null;
  tagSelected: any = null;
  displayGroupSelected: any = null;
  displayTypeSelected: any = null;
  //yearSelected: any = "";
  imageSelected: any = [];
  weekSelected: any = [];
  activitySelected: any = [];

  loadingCountries = false;
  loadingModules = false;
  loadingCategories = false;
  loadingPosRegions = false;
  loadingChannels = false;
  loadingSubChannels = false;
  loadingChannelSegments = false;
  loadingFormats = false;
  loadingWeeks = false;

  dateSelected: any = new Date();
  minDate: any;
  maxDate: any;
  lastDay: any;

  _allPosRegions: any = { 'KCC_Pos_Region__c': null, 'label': 'All' };
  _allChannelSegments: any = { 'KCC_POS_SubChannel__c': null, 'KCC_POS_Channel_Segment__c': null, 'label': 'All' };
  _allFormats: any = { 'KCC_POS_Channel_Segment__c': null, 'KCC_Format__c': null, 'label': 'All' };
  _allModules: any = { 'Name': 'All' };
  _allCategories: any = { attributes: null, KCC_Business_Category__c: 'All', label: 'All' }
  _allResponsibles: any = { attributes: null, KCC_Business_Category__c: 'All', label: 'All' }
  _allWeeks: any = { end: null, start: null, week: null, description: 'All' }

  visible: boolean = false;
  loadingImages: boolean = false;

  checked: boolean = false;
  constructor(private svcMessage: MessageService, private svcCountries: CountriesService, private svcPosRegions: PosRegionsService,private svcChannels: ChannelsService, private svcChannelSegments: ChannelSegmentsService,
    private svcFormats: FormatsService, private svcCategories: CategoriesService, private svcModules: ModulesService, private svcSubChannels: SubChannelsService, private svcFiles: FilesService) { }

  ngOnInit() {
    //this.yearsGet();
    this.getCountries();
    this.getPosRegions();
    this.getChannels();
    this.getSubChannels();
    this.getChannelSegments();
    this.getFormats();
    this.getModules();
    this.getCategories();
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = month - 11; //(month === 0) ? 11 : month - 1;
    let prevYear = (prevMonth < 0) ? year - 1 : year//(prevMonth === 11) ? year - 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.maxDate = new Date();
    this.maxDate.setMonth(month);
    this.maxDate.setFullYear(year);

    this.getWeeksStartAndEndInMonth(this.dateSelected.getFullYear(), this.dateSelected.getMonth());
  }

  getCountries(): void {

    this.loadingCountries = true;
    this.svcCountries.get()
      .subscribe(data => {

        if (data) {
         // this._countries = data.records;
          this._countries = data.records.sort((a: { cgcloud__Description__c: string; }, b: { cgcloud__Description__c: string; }) => {
            return (a.cgcloud__Description__c < b.cgcloud__Description__c ? -1 : 1);
          });
          this.countrySelected = this._countries[0].Name;
        }

        this.loadingCountries = false;
        //this.getPosRegions();
        //this.getChannels();
      },
        error => {
          this.loadingCountries = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of countries can not be loaded.' });
        });
  }

  getPosRegions(): void {

    this.loadingPosRegions = true;
    this._posRegions = [];

    this.svcPosRegions.get()
      .subscribe(data => {
        if (data) {
         // this._posRegions = data.records;
          this._posRegions = data.records.sort((a: { label: string; }, b: { label: string; }) => {
            return (a.label < b.label ? -1 : 1);
          });
          this.filterPosRegionsByCountry();
        }
        this.loadingPosRegions = false;
      },
        error => {
          this.loadingPosRegions = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of pos regions can not be loaded.' });
        });
  }

  getCategories(): void {

    this.loadingCategories = true;
    this._categories = [];
    var categories: any = [];

    this.svcCategories.get()
      .subscribe(data => {

        if (data) {
          for (let i = 0; i < data.records.length; i++) {
            if (data.records[i].KCC_Business_Category__c !== null) {
              categories.push(data.records[i]);
            }
         }

         this._categories = categories.sort((a: { label: string; }, b: { label: string; }) => {
          return (a.label < b.label ? -1 : 1);
        });

        this._categories.unshift(this._allCategories);
        this.categorySelected = this._categories[0].KCC_Business_Category__c;

          this.loadingCategories = false;

        }
      },
        error => {
          this.loadingCategories = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of categories can not be loaded.' });
        });
  }

  getChannels(): void {

    this.loadingChannels = true;
    this._channels = [];

    this.svcChannels.get()
      .subscribe(data => {
        if (data) {
          
          //this._channels = data.records;

         this._channels = data.records.sort((a: { label: string; }, b: { label: string; }) => {
          return (a.label < b.label ? -1 : 1);
        });

          this.filterChannelsByCountry();
        }
        this.loadingChannels = false;
      },
        error => {
          this.loadingChannels = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of channels can not be loaded.' });
        });
  }

  getChannelSegments(): void {

    this.loadingChannelSegments = true;
    this._channelSegments = [];

    this.svcChannelSegments.get()
      .subscribe(data => {
        if (data)
         // this._channelSegments = data.records;
          
         this._channelSegments = data.records.sort((a: { label: string; }, b: { label: string; }) => {
          return (a.label < b.label ? -1 : 1);
        });
        this.filterChannelSegmentsBySubChannel();
        this.loadingChannelSegments = false;
      },
        error => {
          this.loadingChannelSegments = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of channel segments can not be loaded.' });
        });
  }

  getFormats(): void {

    this._formats = [];
    this.loadingFormats = true;
    this.svcFormats.get()
      .subscribe(data => {
        if (data)
         // this._formats = data.records;
          
        this._formats = data.records.sort((a: { label: string; }, b: { label: string; }) => {
         return (a.label < b.label ? -1 : 1);
       });
        this.filterFormatsByChannelSegment();
        this.loadingFormats = false;
      },
        error => {
          this.loadingFormats = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of formats can not be loaded.' });
        });
  }

  getModules(): void {

    this.loadingModules = true;
    this._modules = [];

    this.svcModules.get()
      .subscribe(data => {
        if (data)
       //this._modules = data.records;
        this._modules = data.records.sort((a: { Name: string; }, b: { Name: string; }) => {
          return (a.Name < b.Name ? -1 : 1);
        });
        this._modules.unshift(this._allModules);
        this.loadingModules = false;
      },
        error => {
          this.loadingModules = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of modules can not be loaded.' });
        });
  }

  getSubChannels(): void {

    this.loadingSubChannels = true;
    this._subChannels = [];

    this.svcSubChannels.get()
      .subscribe(data => {
        if (data) {
         // this._subChannels = data.records;
          
          this._subChannels = data.records.sort((a: { label: string; }, b: { label: string; }) => {
           return (a.label < b.label ? -1 : 1);
         });
          this.filterSubChannelsByChannel();
        }
        this.loadingSubChannels = false;
      },
        error => {
          this.loadingSubChannels = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of sub channels can not be loaded.' });
        });
  }

  filterPosRegionsByCountry(): void {

    this._posRegionsFiltered = [];

    for (let i = 0; i < this._posRegions.length; i++) {

      if (this._posRegions[i].KCC_Users_Sales_Org__c === this.countrySelected && this._posRegions[i].label !== null) {
        this._posRegionsFiltered.push(this._posRegions[i]);
      }
    }

    this._posRegionsFiltered.unshift(this._allPosRegions);
    this.posRegionSelected = this._posRegionsFiltered[0].KCC_Pos_Region__c;

    //if (this._posRegionsFiltered.length > 0) {
    //  this.posRegionSelected = this._posRegionsFiltered[0].KCC_Pos_Region__c;
    //}
  }

  filterChannelsByCountry(): void {

    this._channelsFiltered = [];

    for (let i = 0; i < this._channels.length; i++) {

      if (this._channels[i].KCC_Users_Sales_Org__c === this.countrySelected && this._channels[i].label !== null) {
        this._channelsFiltered.push(this._channels[i]);
      }
    }

    if (this._channelsFiltered.length > 0) {
      this.channelSelected = this._channelsFiltered[0].KCC_POS_Channel__c;
      this.filterSubChannelsByChannel();
    }
  }

  filterSubChannelsByChannel(): void {

    this._subChannelsFiltered = [];

    for (let i = 0; i < this._subChannels.length; i++) {

      if (this._subChannels[i].KCC_POS_Channel__c === this.channelSelected && this._subChannels[i].label !== null) {
        this._subChannelsFiltered.push(this._subChannels[i]);
      }
    }

    if (this._subChannelsFiltered.length > 0) {
      this.subChannelSelected = this._subChannelsFiltered[0].KCC_POS_SubChannel__c;
      this.filterChannelSegmentsBySubChannel();
    }
    //this.getFiles();
  }

  filterChannelSegmentsBySubChannel(): void {

    this._channelSegmentsFiltered = [];

    for (let i = 0; i < this._channelSegments.length; i++) {

      if (this._channelSegments[i].KCC_POS_SubChannel__c === this.subChannelSelected && this._channelSegments[i].label !== null) {
          this._channelSegmentsFiltered.push(this._channelSegments[i]);
        }
    }

    this._channelSegmentsFiltered.unshift(this._allChannelSegments);

    this.channelSegmentSelected = this._channelSegmentsFiltered[0].KCC_POS_Channel_Segment__c;
    this.filterFormatsByChannelSegment();
  
  }

  filterFormatsByChannelSegment(): void {

    this._formatsFiltered = [];

    for (let i = 0; i < this._formats.length; i++) {

      if (this._formats[i].KCC_POS_Channel_Segment__c === this.channelSegmentSelected && this._formats[i].label !== null) {
        this._formatsFiltered.push(this._formats[i]);
      }
    }


    this._formatsFiltered.unshift(this._allFormats);
      this.formatSelected = this._formatsFiltered[0].KCC_Format__c;
   

    // this.getFiles();
  }

  filterFiles(): void {

    this._filesFiltered = this._filesFinal.filter(x => typeof this.selectedCustomers === 'undefined' || !this.selectedCustomers.length || this.selectedCustomers.some(y => y.Id === x.customerId))
      .filter(x => typeof this.selectedPos === 'undefined' || !this.selectedPos.length || this.selectedPos.some(y => y.Id === x.posId))
      .filter(x => typeof this.selectedResponsibles === 'undefined' || !this.selectedResponsibles.length || this.selectedResponsibles.some(y => y.Id === x.responsibleId))
      .filter(x => typeof this.selectedDisplayGroups === 'undefined' || !this.selectedDisplayGroups.length || this.selectedDisplayGroups.some(y => y.description === x.displayGroup))
      .filter(x => typeof this.selectedDisplayTypes === 'undefined' || !this.selectedDisplayTypes.length || this.selectedDisplayTypes.some(y => y.description === x.displayType));

  }

  getFiles(): void {

    this.loadingImages = true;
   // var date = new Date(this.dateSelected.getFullYear(), this.dateSelected.getMonth(), 1);
    this._filesFinal = [];
    var category = this.categorySelected === 'All' ? null : this.categorySelected;
    var module = this.moduleSelected === 'All' ? null : this.moduleSelected;
    var startDay = this.weekSelected.start === null ? 1 : this.weekSelected.start;
    var endDay = this.weekSelected.end === null ? this.lastDay : this.weekSelected.end;

    this.svcFiles.get(this.countrySelected, category, module, this.activitySelected, this.posRegionSelected, this.channelSelected, this.subChannelSelected, this.channelSegmentSelected, this.formatSelected,
      this.dateSelected.getFullYear(), this.dateSelected.getMonth() + 1, startDay, endDay, this.limitSelected)
      .subscribe(data => {

        if (data) {

          for (let i = 0; i < data.records.length; i++) {


            if (data.records[i].ContentDocumentLinks){ 

              for (let j = 0; j < data.records[i].ContentDocumentLinks.records.length; j++) {

                  if (data.records[i].ContentDocumentLinks.records[j].ContentDocument.FileType === 'JPG' || data.records[i].ContentDocumentLinks.records[j].ContentDocument.FileType === 'PNG' || data.records[i].ContentDocumentLinks.records[j].ContentDocument.FileType === 'GIF' || data.records[i].ContentDocumentLinks.records[j].ContentDocument.FileType === 'SVG') {
                    console.log(data.records[i]);

                    this._filesFinal.push({
                      id: data.records[i].cgcloud__Visit__r.Id,
                      posId: data.records[i].cgcloud__Visit__r.Account.Id,
                      pos: data.records[i].cgcloud__Visit__r.Account.Name,
                      customerId: data.records[i].cgcloud__Visit__r.Account.Parent === null ? null : data.records[i].cgcloud__Visit__r.Account.Parent.Id,
                      customer: data.records[i].cgcloud__Visit__r.Account.Parent === null ? null : data.records[i].cgcloud__Visit__r.Account.Parent.Name,
                      accountNumber: data.records[i].cgcloud__Visit__r.Account.AccountNumber,
                      posRegion: data.records[i].cgcloud__Visit__r.Account.KCC_Pos_Region__c,
                      posRegionName: data.records[i].cgcloud__Visit__r.Account.KCC_Pos_Region__c === null ? '' : this._posRegions.find(x => x.KCC_Pos_Region__c === data.records[i].cgcloud__Visit__r.Account.KCC_Pos_Region__c).label,
                      posChannel: data.records[i].cgcloud__Visit__r.Account.KCC_POS_Channel__c,
                      posChannelName: data.records[i].cgcloud__Visit__r.Account.KCC_POS_Channel__c === null ? '' : this._channels.find(x => x.KCC_POS_Channel__c === data.records[i].cgcloud__Visit__r.Account.KCC_POS_Channel__c).label,
                      posSubChannel: data.records[i].cgcloud__Visit__r.Account.KCC_POS_SubChannel__c,
                      posSubChannelName: data.records[i].cgcloud__Visit__r.Account.KCC_POS_SubChannel__c === null ? '' : this._subChannels.find(x => x.KCC_POS_SubChannel__c === data.records[i].cgcloud__Visit__r.Account.KCC_POS_SubChannel__c).label,
                      posChannelSegment: data.records[i].cgcloud__Visit__r.Account.KCC_POS_Channel_Segment__c,
                      posChannelSegmentName: data.records[i].cgcloud__Visit__r.Account.KCC_POS_Channel_Segment__c === null ? '' : this._channelSegments.find(x => x.KCC_POS_Channel_Segment__c === data.records[i].cgcloud__Visit__r.Account.KCC_POS_Channel_Segment__c).label,
                      format: data.records[i].cgcloud__Visit__r.Account.KCC_Format__c,
                      formatName: data.records[i].cgcloud__Visit__r.Account.KCC_Format__c === null ? '' : this._formats.find(x => x.KCC_Format__c === data.records[i].cgcloud__Visit__r.Account.KCC_Format__c).label,
                      category: data.records[i].cgcloud__Product__r === null ? null : data.records[i].cgcloud__Product__r.KCC_Business_Category__c,
                      categoryName: data.records[i].cgcloud__Product__r === null ? '' : this._categories.find(x => x.KCC_Business_Category__c === data.records[i].cgcloud__Product__r.KCC_Business_Category__c).label,
                      module: data.records[i].cgcloud__Visit__r.cgcloud__Visit_Template__r.Name,
                      activity: data.records[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_List__r.cgcloud__Job_Definition_List_Template__r.KCC_DPSM_Relevant__c,
                      displayGroup: data.records[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.Display_Group__c,
                      displayType: data.records[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.KCC_Display_Type__c,
                      city: data.records[i].cgcloud__Visit__r.Account.ShippingCity,
                      country: data.records[i].cgcloud__Visit__r.Account.ShippingCountry,
                      state: data.records[i].cgcloud__Visit__r.Account.ShippingState,
                      status: data.records[i].cgcloud__Visit__r.Status,
                      durationEfective: data.records[i].cgcloud__Visit__r.cgcloud__Duration_Effective__c,
                      durationMinutes: data.records[i].cgcloud__Visit__r.cgcloud__Duration_In_Minutes__c,
                      subject: data.records[i].cgcloud__Visit__r.cgcloud__Subject__c,
                      startTime: data.records[i].cgcloud__Visit__r.PlannedVisitStartTime,
                      endTime: data.records[i].cgcloud__Visit__r.PlannedVisitEndTime,
                      type: data.records[i].cgcloud__Visit__r.KCC_Sub_Task_Type__c,
                      salesOrg: data.records[i].cgcloud__Visit__r.Account.cgcloud__Sales_Org__c,
                      latitude: data.records[i].cgcloud__Visit__r.cgcloud__Geolocation__Latitude__s,
                      longitude: data.records[i].cgcloud__Visit__r.cgcloud__Geolocation__Longitude__s,
                      tags: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.TagCsv,
                      responsibleId: data.records[i].cgcloud__Visit__r.cgcloud__Responsible__r.Id,
                      responsible: data.records[i].cgcloud__Visit__r.cgcloud__Responsible__r.Name,
                      areaTag: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.cgcloud__Area_Tag__c,
                      competitorTag: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.cgcloud__Competitor_Tag__c,
                      posTypeTag: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.cgcloud__POS_Type_Tag__c,
                      usage: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.cgcloud__Usage__c,
                      descriptionDocument: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.Description,
                      visitDate: data.records[i].cgcloud__Visit_Date__c,
                      imageTitle: data.records[i].ContentDocumentLinks.records[j].ContentDocument.Title,
                      creationDate: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.CreatedDate,
                      url: data.records[i].ContentDocumentLinks.records[j].ContentDocument.LatestPublishedVersion.VersionDataUrl,
                      checked: this.checked
                    });

                } 

                }
              }

          }
          this._filesFiltered = this._filesFinal;
          this._files = data.records;
        }
        this.loadingImages = false;
        this.getFilters();
      },
        error => {
          this.loadingImages = false;
          this.svcMessage.add({ key: 'message', severity: 'error', summary: 'Ooops!!', detail: 'The list of files can not be loaded.' });
        });
  }

  getFilters(): void {

    var customers = [];
    var pos = [];
    var responsibles = [];
    var displayGroups = [];
    var displayTypes = [];

    this._responsibles = [];
    this._customers = [];
    this._pos = [];
   // this._kpis = [];
    //this._tags = [];
    this._displayGroups = [];
    this._displayTypes = [];

    for (let i = 0; i < this._files.length; i++) {

      var foundResponsible = false;
      var foundCustomer = false;
      var foundPOS = false;
      //var foundKpi = false;
      // var foundTag = false;
      var foundDisplayGroup = false;
      var foundDisplayType = false;

      for (let j = 0; j < customers.length; j++) {
        if (this._files[i].cgcloud__Visit__r.Account.Parent !== null && this._files[i].cgcloud__Visit__r.Account.Parent.Id === customers[j].Id) {
          foundCustomer = true;
          break;
        }
      }

      for (let j = 0; j < pos.length; j++) {
        if (this._files[i].cgcloud__Visit__r.Account.Id === pos[j].Id) {
          foundPOS = true;
          break;
        }
      }

      for (let j = 0; j < responsibles.length; j++) {
        if (this._files[i].cgcloud__Visit__r.cgcloud__Responsible__r.Id === responsibles[j].Id) {
          foundResponsible = true;
          break;
        }
      }

      //for (let j = 0; j < this._responsibles.length; j++) {
      //  if (this._files[i].cgcloud__Visit__r.cgcloud__Responsible__r.ACCL__Job_Definition__r.ACCL__Job_Definition_List__r.ACCL__Job_Definition_List_Template__r.name = this._kpis[j].name) {
      //    foundKpi = true;
      //    break;
      //  }
      //}

      for (let j = 0; j < displayGroups.length; j++) {
        if (this._files[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.Display_Group__c === displayGroups[j].description) {
          foundDisplayGroup = true;
          break;
        }
      }

      for (let j = 0; j < displayTypes.length; j++) {
        if (this._files[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.KCC_Display_Type__c === displayTypes[j].description) {
          foundDisplayType = true;
          break;
        }
      }

      //if (this._files[i].ContentDocumentLinks) {
      //  for (let l = 0; l < this._files[i].ContentDocumentLinks.records.length; l++) {

      //    for (let j = 0; j < this._tags.length; j++) {
      //      if (this._files[i].ContentDocumentLinks.records[l].ContentDocument.LatestPublishedVersion.TagCsv = this._tags[j].description) {
      //        foundTag = true;
      //        break;
      //      }
      //    }
      //  }

      //}

      if (!foundCustomer && this._files[i].cgcloud__Visit__r.Account.Parent !== null) {
       customers.push({
          Id: this._files[i].cgcloud__Visit__r.Account.Parent.Id,
          name: this._files[i].cgcloud__Visit__r.Account.Parent.Name + " (" + this._files[i].cgcloud__Visit__r.Account.Parent.Id + ")"
        })
      }

      if (!foundPOS) {
        pos.push({
          Id: this._files[i].cgcloud__Visit__r.Account.Id,
          name: this._files[i].cgcloud__Visit__r.Account.Name + " (" + this._files[i].cgcloud__Visit__r.Account.Id + ")"
        })
      }

      if (!foundResponsible) {
        responsibles.push({
          Id: this._files[i].cgcloud__Visit__r.cgcloud__Responsible__r.Id,
          name: this._files[i].cgcloud__Visit__r.cgcloud__Responsible__r.Name + " (" + this._files[i].cgcloud__Visit__r.cgcloud__Responsible__r.Id + ")"
        })
      }

      //if (!foundKpi) {
      //  this._kpis.push({
      //    name: this._files[i].cgcloud__Visit__r.ACCL__Responsible__r.ACCL__Job_Definition__r.ACCL__Job_Definition_List__r.ACCL__Job_Definition_List_Template__r.name
      //  })
      //}

      if (!foundDisplayGroup) {
        if (this._files[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.Display_Group__c !== null)
          displayGroups.push({
            description: this._files[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.Display_Group__c
          })
      }

      if (!foundDisplayType) {
        if (this._files[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.KCC_Display_Type__c !== null)
          displayTypes.push({
            description: this._files[i].cgcloud__Job_Definition__r.cgcloud__Job_Definition_Template__r.KCC_Display_Type__c
          })
      }

    }

    this._customers = customers.sort((a: { name: string; }, b: { name: string; }) => {
      return (a.name < b.name ? -1 : 1);
    });

    this._pos = pos.sort((a: { name: string; }, b: { name: string; }) => {
      return (a.name < b.name ? -1 : 1);
    });

    this._responsibles = responsibles.sort((a: { name: string; }, b: { name: string; }) => {
      return (a.name < b.name ? -1 : 1);
    });

    this._displayGroups = displayGroups.sort((a: { description: string; }, b: { description: string; }) => {
      return (a.description < b.description ? -1 : 1);
    });

    this._displayTypes = displayTypes.sort((a: { description: string; }, b: { description: string; }) => {
      return (a.description < b.description ? -1 : 1);
    });
  }

  getWeeksStartAndEndInMonth(year: number, month: number) {
  let weeks = [],
  //  firstDate = new Date(year, month - 1, 1),
    lastDate = new Date(year, month, 0),
    numDays = lastDate.getDate();

    this.lastDay = numDays;

  let start = 1;
    let end = this.GetfirstWeekEnd(month, year).getDate();
    let week = 1;

    while (start <= numDays) {
      weeks.push({ week: week, description: 'From ' + start + ' to ' + end, start: start, end: end });
      start = end + 1;
      end = end + 7;
      end = start === 1 && end === 8 ? 1 : end;
      if (end > numDays) {
        end = numDays;
      }
      week += 1;
    }
    this._weeks = weeks;

    this._weeks.unshift(this._allWeeks);
    

}

  GetfirstWeekEnd(month: number, year: number) {
  var firstDay = new Date(year + "-" + (month + 1) + "-" + 1);
  let dayOfWeek = firstDay.getDay();
    var endday;

  var startCount = dayOfWeek;
  if (startCount != 0) {
    endday = new Date(firstDay.setDate(firstDay.getDate() + (7 - dayOfWeek)));
  }
  else {
    endday = new Date(firstDay.setDate(firstDay.getDate() + 1));

  }
  return endday;
}


  checkItemsToPrint(): void {

    for (let i = 0; i < this._filesFinal.length; i++) {
      this._filesFinal[i].checked = this.checked;
    }
  }

  showDialog(item: any) {
    this.imageSelected = item;
    this.visible = true;
  }
  print() {
  
      window.print();

  }


}
